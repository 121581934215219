/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit';
import { ProductionCategoriesKeys } from 'enums/ProductionCategories';
import moment from 'moment';

const initialState = {
  itemsPerProductionUnit: [],
  itemsPerProductionType: [],
  error: {},
  lastUpdateDate: moment.utc().format('DD/MM/YYYY - HH[h]mm'),
  globalProductionDetailsPerType: {},
};

// const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    loadDataSuccess: (state, action) => {
      const { generationDate } =
        action.payload.itemsPerProductionUnit.items.find((item) => {
          return item.productionCategory === 'HYDRAULICS';
        }).bilan;
      // eslint-disable-next-line no-unused-vars
      const allObject = Object.entries(
        action.payload.globalProductionDetailsPerType,
      )
        .filter((item) => {
          return item[0] !== 'ALL';
        })
        .reduce(
          (accumulator, currentValue) => {
            return {
              filiere_activable:
                accumulator.filiere_activable +
                currentValue[1].filiere_activable,
              filiere_activablepourcent:
                accumulator.filiere_activablepourcent +
                currentValue[1].filiere_activablepourcent,
              filiere_capaciteinstallee:
                accumulator.filiere_capaciteinstallee +
                currentValue[1].filiere_capaciteinstallee,
              filiere_inaccessible:
                accumulator.filiere_inaccessible +
                currentValue[1].filiere_inaccessible,
              filiere_inaccessiblepourcent:
                accumulator.filiere_inaccessiblepourcent +
                currentValue[1].filiere_inaccessiblepourcent,
              filiere_maintenance:
                accumulator.filiere_maintenance +
                currentValue[1].filiere_maintenance,
              filiere_maintenancepourcent:
                accumulator.filiere_maintenancepourcent +
                currentValue[1].filiere_maintenancepourcent,
              filiere_production:
                accumulator.filiere_production +
                currentValue[1].filiere_production,
              filiere_productionpourcent:
                accumulator.filiere_productionpourcent +
                currentValue[1].filiere_productionpourcent,
            };
          },
          {
            filiere_activable: 0,
            filiere_activablepourcent: 0,
            filiere_capaciteinstallee: 0,
            filiere_inaccessible: 0,
            filiere_inaccessiblepourcent: 0,
            filiere_maintenance: 0,
            filiere_maintenancepourcent: 0,
            filiere_production: 0,
            filiere_productionpourcent: 0,
          },
        );
      return {
        ...state,
        lastUpdateDate: moment(generationDate).format('DD/MM/YYYY - HH[h]mm'),
        itemsPerProductionUnit: action.payload.itemsPerProductionUnit.items.map(
          (item) => {
            if (
              item.productionUnit === 'SAINT-AVOLD' &&
              item.productionCategory === 'FOSSIL_HARD_COAL'
            ) {
              return {
                ...item,
                productionUnit: 'SAINT-AVOLD-1',
              };
            }
            return { ...item };
          },
        ),
        itemsPerProductionType:
          action.payload.itemsPerProductionType.items.filter((item) => {
            return ![
              ProductionCategoriesKeys.WASTE,
              ProductionCategoriesKeys.ALL,
            ].includes(item.productionCategory);
          }),
        globalProductionDetailsPerType: {
          ...action.payload.globalProductionDetailsPerType,
        },
      };
    },
    loadDataFail: (state, action) => {
      return {
        ...state,
        error: action.payload.error,
      };
    },
  },
});

export const { loadDataSuccess, loadDataFail } = dataSlice.actions;

export default dataSlice.reducer;
