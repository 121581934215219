import { Button, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ALL, HYDRAULICS, WIND } from '../../../../../../../utils/infos';
import Infos from '../../../../../../../images/infos.svg';

const StyledRow = styled(Row)`
  .text-icon {
    align-items: center;
    column-gap: 20px;
    height: 32px;
    @media only screen and (max-width: 767px) {
      justify-content: space-between;
    }
  }
`;

function InfosIcon({ title, content, showModal }) {
  return (
    <Button
      onClick={() => {
        return showModal(title, content);
      }}
      type="text"
      style={{ padding: 0 }}
    >
      <img src={Infos} alt="infos" />
    </Button>
  );
}
function TopSiderInfosHead({ currentCategory, type, showModal }) {
  return (
    <StyledRow>
      {type === 'disponible' && (
        <Col span={24}>
          <Row className="text-icon">
            <Col className="text-1">Disponible</Col>
            {currentCategory === 'ALL' && (
              <Col>
                <InfosIcon
                  title={ALL[type].title}
                  content={ALL[type].content}
                  showModal={showModal}
                />
              </Col>
            )}
            {currentCategory === 'HYDRAULICS' && (
              <Col>
                <InfosIcon
                  title={HYDRAULICS[type].title}
                  content={HYDRAULICS[type].content}
                  showModal={showModal}
                />
              </Col>
            )}
            {currentCategory === 'WIND' && (
              <Col>
                <InfosIcon
                  title={WIND[type].title}
                  content={WIND[type].content}
                  showModal={showModal}
                />
              </Col>
            )}
          </Row>
        </Col>
      )}
      {type === 'inaccessible' && (
        <Col span={24}>
          <Row className="text-icon">
            <Col className="text-1">Inaccessible</Col>
            {currentCategory === 'ALL' && (
              <Col>
                <InfosIcon
                  title={ALL.inaccessible.title}
                  content={ALL.inaccessible.content}
                  showModal={showModal}
                />
              </Col>
            )}
            {currentCategory === 'HYDRAULICS' && (
              <Col>
                <InfosIcon
                  title={HYDRAULICS.inaccessible.title}
                  content={HYDRAULICS.inaccessible.content}
                  showModal={showModal}
                />
              </Col>
            )}
          </Row>
        </Col>
      )}
    </StyledRow>
  );
}

export default TopSiderInfosHead;
