/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from '@emotion/styled';
import { Card, Col, Row } from 'antd';
import React from 'react';
import useDeviceDetect from 'utils/useDeviceDetect';
import { useSelector } from 'react-redux';
import { selectCurrentCategory } from 'redux/selectors/crossSelectors';
import BottomSider from './bottom-sider';
import TopSider from './top-sider';

const StyledRow = styled(Row)`
  min-width: 275px;
  .top-card {
    .ant-card-body {
      padding-right: 0;
    }
  }
  .bottom-card {
    .ant-card-body {
      padding-top: 0;
    }
  }
  .sider-infos {
    background-color: #009dd1;
    border-radius: 8px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 15px;
    padding-right: 30px;
  }
  .close-img {
    position: absolute;
    right: 16px;
    cursor: pointer;
    width: 26px;
    height: 26px;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 20px;
    .ant-card {
      width: 90%;
      margin: auto;
    }
    .top-card {
      .ant-card-body {
        padding: 10px;
      }
    }
  }
`;
function Siders() {
  const isMobile = useDeviceDetect();
  const currentCategory = useSelector(selectCurrentCategory);
  return (
    <StyledRow style={{ rowGap: 16 }}>
      <Col span={24}>
        <Card bordered={false} className="top-card">
          <TopSider />
        </Card>
      </Col>
      {!isMobile &&
        !['HYDRAULICS', 'WIND', 'ALL'].includes(currentCategory) && (
          <Col span={24}>
            <Card
              title="Moyens de production"
              bordered={false}
              className="bottom-card"
            >
              <BottomSider />
            </Card>
          </Col>
        )}
    </StyledRow>
  );
}

export default Siders;
