/* eslint-disable import/prefer-default-export */
export const ALL = {
  disponible: {
    title: 'Disponible',
    content: {
      __html:
        'A un instant donné, capacité pouvant en théorie servir à produire de l’électricité. Elle est découpée en deux catégories :<br /><br /><ul><li>La capacité « en production » <strong>(vert)</strong> correspond à la capacité actuellement utilisée pour produire de l’électricité en France métropolitaine hors Corse, toutes filières de production confondues. Les puissances sont des moyennes horaires, contrairement à Eco2mix (moyenne 15 min). Le pourcentage représente la part de cette capacité par rapport à la capacité installée de l’ensemble des moyens de production. Seules les filières de production les plus importantes sont détaillées. Sauf mention contraire dans les filières correspondantes, les données de production sont issues de l’API « Actual Generation », que ce soit au niveau des filières ou des centrales.</li><br /><br /><li> La capacité « utilisable » <strong>(bleu)</strong> correspond à des moyens de production activables mais non utilisés pour le moment.</li></ul>',
    },
  },
  inaccessible: {
    title: 'Inaccessible',
    content: {
      __html:
        'A un instant donné, capacité qui ne peut pas servir à produire de l’électricité. Elle est découpée en deux catégories :<br /><br /><ul><li>La capacité «non utilisable» <strong>(gris)</strong> est la capacité inaccessible à cause de conditions météo.</li><li>La capacité « indisponible » <strong>(rouge)</strong> correspond aux indisponibilités des moyens de production, par exemple pour maintenance. Une indisponibilité porte sur un moyen de production donné et peut être partielle ou totale. Les données utilisées sont issues de l’API « Unavailability Additional Information ». Les indisponibilités sont agrégées par centrale puis par filière de production.',
    },
  },
};

export const HYDRAULICS = {
  disponible: {
    title: 'Disponible',
    content: {
      __html:
        'Pour l’affichage sur la carte, il a été décidé de ne considérer que la production des centrales raccordées au réseau électrique géré par RTE et de les regrouper en 9 zones géographiques, représentées sur la carte par une centrale fictive et une aire délimitée par des pointillés. La capacité installée de ces agrégats représente un peu plus de 90% de la capacité installée totale pour l’hydraulique.<br /><br />Au niveau de la filière, la valeur de production affichée est issue de l’API « Actual Generation ».',
    },
  },
  inaccessible: {
    title: 'Inaccessible',
    content: {
      __html:
        'En raison des données exploitables, la distinction entre la capacité « utilisable » <strong>(bleu)</strong> et la capacité « inutilisable » <strong>(gris)</strong> n’est possible qu’au niveau de l’ensemble de la filière.',
    },
  },
};

export const WIND = {
  disponible: {
    title: 'Disponible',
    content: {
      __html:
        'La filière est divisée en deux sous-filières : l’éolien en mer (offshore) et l’éolien terrestre. Les centrales de la première catégorie sont représentées sur la carte, et leurs indisponibilités éventuelles sont issues de l’API « Unavailability Additional Information ».<br /><br />La sous-filière « éolien terrestre » est représentée par une centrale fictive. Sa production est obtenue à l’aide de l’API « Actual Generation ».',
    },
  },
};
