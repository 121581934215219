import React from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

const StyledRow = styled(Row)`
  opacity: ${(props) => {
    return props.value === 0 ? 0.2 : 1;
  }};
  flex-wrap: nowrap;

  column-gap: 9px;
  align-items: center;
  .border-left {
    flex-basis: 5px;
    height: 33px;
    @media only screen and (max-width: 767px) {
      height: 20px;
    }
    border-radius: 2px;

    &.prod {
      background: #3cd626;
    }
    &.used {
      background: #0081cf;
    }
    &.inacc {
      background: #bebebe;
    }
    &.indispo {
      background: #d0574f;
    }
  }
  .border-content {
    @media only screen and (max-width: 767px) {
      flex-basis: 208px;
      .border-content-mobile {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .border-content-row {
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      @media only screen and (max-width: 767px) {
        width: 95px;
      }
      .item-1 {
        flex-basis: 70px;
        @media only screen and (max-width: 767px) {
          flex-basis: 60px;
        }
      }
      .item-3 {
        @media only screen and (max-width: 767px) {
          flex-basis: 30px;
        }
      }
    }
    .separator-percent-text {
      width: 0px;
      height: 9.5px;
      border: 1px solid #000000;
      margin-left: 10px;
      margin-right: 10px;
      @media only screen and (max-width: 767px) {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
`;
function TopSiderInfosItem({ text, value, percent, type }) {
  return (
    <StyledRow value={value}>
      <Col className={`border-left ${type}`} />
      <Col className="border-content">
        <Row className="border-content-mobile">
          <Col className="text-1">{text}</Col>
          <Col>
            <Row className="border-content-row">
              <Col className="text-1-3 item-1">{value} GW</Col>
              <Col className="separator-percent-text" />
              <Col className="text-1-3 item-3">{`${percent}%`}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </StyledRow>
  );
}

export default TopSiderInfosItem;
