/* eslint-disable react/no-array-index-key */
import { Row, Tooltip } from 'antd';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import useDeviceDetect from 'utils/useDeviceDetect';
import BarIndication from './BarIndication';

function PieChartItem({ data, productionUnitName, pmax, productionCategory }) {
  const isMobile = useDeviceDetect();
  const handleClick = () => {
    return 33;
  };
  const getSize = () => {
    if (pmax > 5000) {
      return !isMobile ? 68 : 38;
    }
    if (pmax > 3000) {
      return !isMobile ? 54 : 24;
    }
    if (pmax > 1500) {
      return !isMobile ? 40 : 18;
    }
    if (pmax > 500) {
      return !isMobile ? 34 : 16;
    }
    return !isMobile ? 25 : 16;
  };
  const size = getSize();
  return (
    <ResponsiveContainer width="90%" height="100%">
      <Row>
        <Tooltip
          title={
            <BarIndication
              productionUnitName={productionUnitName}
              productionUnitPmax={pmax}
              down={data[1].value}
              prod={data[2].value}
              productionCategory={productionCategory}
            />
          }
          placement="topRight"
          style={{ zIndex: 9999 }}
          color="#ffffff"
        >
          <PieChart width={size} height={size} onMouseEnter={handleClick}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={size / 2}
              fill="#8884d8"
              dataKey="value"
              animationBegin={100}
              animationDuration={1000}
              startAngle={90}
              endAngle={450}
            >
              {[...data].map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color}
                    strokeWidth={0}
                  />
                );
              })}
            </Pie>
          </PieChart>
        </Tooltip>
      </Row>
    </ResponsiveContainer>
  );
}

export default PieChartItem;
