/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  selectGlobalProductionDetailsPerType,
} from 'redux/selectors/dataSelectors';
import useDeviceDetect from 'utils/useDeviceDetect';
import RightArrow from 'images/sector-modal/arrow_right.svg';
import { ProductionCategories } from 'enums/ProductionCategories';
import { Image } from './Image';
import { Jauges } from './Jauges';

const StyledRow = styled(Row)`
  column-gap: 5px;
  margin-bottom: 15px;
  height: 36px;
  @media only screen and (max-width: 767px) {
    height: 78px;
    flex-wrap: warp;
  }
  .image-row {
    width: 170px;
    column-gap: 13px;
    align-items: end;
    position: relative;
    bottom: 8px;
    @media only screen and (max-width: 767px) {
      width: 30px;
    }
  }
  .capacity {
    color: #767676;
  }
  .pourcent-1 {
    width: 50px;
    .percent-1 {
      color: #37cb0f;
      width: 39px;
    }
  }

`;
export function SectorItem({ sector }) {
  const isMobile = useDeviceDetect();

  const globalDetails = useSelector(selectGlobalProductionDetailsPerType);
  const {filiere_capaciteinstallee, filiere_production, filiere_productionpourcent} = globalDetails[sector];
  return (
    <StyledRow>
      <Col flex={!isMobile ? '150px' : '35px'}>
        <Row className="image-row" wrap={false}>
          <Col>
            <Image sector={sector} />
          </Col>
          {!isMobile && (
            <Col>
              <Row>
                <Col className="boldBody" span={24}>
                  {ProductionCategories.hasOwnProperty(sector)
                    ? ProductionCategories[sector]
                    : sector}
                </Col>
            </Row>
            </Col>
          )}
        </Row>
      </Col>
      <Col flex="80px">
        <Row align="middle">
          <Col className="regularBody" span={24}>
            {`${filiere_capaciteinstallee}GW`}
          </Col>
        </Row>
      </Col>
      <Col flex="67px">
        <Row align="middle">
          <Col className="boldBody" span={24}>
            {`${Number(filiere_production.toFixed(1))}GW`.replace('.', ',')}
          </Col>
        </Row>
      </Col>
      <Col>
        <Row align="middle">
          <Col>
            <img src={RightArrow} alt="rightArrow" />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row wrap={false} align="middle">
          <Col>
            <Row className="pourcent-1" align="middle">
              <Col className="boldBody percent-1">{`${filiere_productionpourcent}%`}</Col>
            </Row>
          </Col>
          <Col>
            <Jauges
              globalDetails={globalDetails[sector]}
            />
          </Col>
        </Row>
      </Col>
    </StyledRow>
  );
}
