import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  row-gap: 15px;
  @media only screen and (max-width: 767px) {
    row-gap: 5px;
  }
`;
function InfoSider() {
  return (
    <StyledRow style={{ rowGap: 15 }}>
      <Col span={24} className="info-sider">
        Page en <span className="bold">version</span>
        <br /> <span className="bold">BETA-TEST</span>
      </Col>
      <Col span={24} className="info-sider-content">
        Cette page est en cours de réalisation, certains éléments peuvent être
        amenés à changer.
      </Col>
    </StyledRow>
  );
}

export default InfoSider;
