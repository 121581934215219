import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SVGMap from 'images/map.svg';
import SVGMapMobile from 'images/map-mobile.svg';
import Rive2 from 'images/rive-2.svg';
import Rive3 from 'images/rive-3.svg';
import AlpesNord from 'images/alpes-nord.svg';
import Pyrennees from 'images/pyrennees.svg';
import MassifCentral from 'images/massif-central.svg';
import ProvenceCotesAzur from 'images/provence-cotes-azur.svg';
import { selectCurrentCategory } from 'redux/selectors/crossSelectors';
import { selectPerUnitByFieldAndProductionUnit } from 'redux/selectors/dataSelectors';
import useDeviceDetect from 'utils/useDeviceDetect';
import _ from 'lodash';
import PieChartItem from './PieChartItem';

const StyledRow = styled(Row)`
  .pie-chart {
    flex-direction: column;
    row-gap: 5px;
  }
  .map {
    position: absolute;
    left: 77.86px;
    top: 38px;
  }
`;
const getSize = (productionUnitPmax, isMobile) => {
  if (productionUnitPmax > 5000) {
    return !isMobile ? 68 : 38;
  }
  if (productionUnitPmax > 3000) {
    return !isMobile ? 54 : 24;
  }
  if (productionUnitPmax > 1500) {
    return !isMobile ? 40 : 18;
  }
  if (productionUnitPmax > 500) {
    return !isMobile ? 34 : 16;
  }
  return !isMobile ? 25 : 16;
};
const getItemClass = (itemClass) => {
  return itemClass
    .toLowerCase()
    .split(' ')
    .join('')
    .split("'")
    .join('')
    .split('ô')
    .join('o')
    .split(/é|è|ê/)
    .join('e');
};
function Map() {
  const currentCategory = useSelector(selectCurrentCategory);
  const dataGroupedByField = useSelector((state) => {
    return selectPerUnitByFieldAndProductionUnit(state, currentCategory);
  });

  const isMobile = useDeviceDetect();
  return (
    <Row>
      <Col className="map-container" span={24}>
        {!isMobile ? (
          <img src={SVGMap} alt="map" className="map" />
        ) : (
          <img src={SVGMapMobile} alt="map" className="map" />
        )}
        {['HYDRAULICS', 'ALL'].includes(currentCategory) && (
          <>
            <img src={Rive2} alt="rive-2" className="rive-2 rives" />
            <img src={Rive3} alt="rive-3" className="rive-3 rives" />
            <img
              src={AlpesNord}
              alt="alpes-nord"
              className="alpes-nord rives"
            />
            <img
              src={MassifCentral}
              alt="massif-central"
              className="massif-central rives"
            />
            <img src={Pyrennees} alt="pyrennees" className="pyrennees rives" />
            <img src={ProvenceCotesAzur} alt="pca" className="pca rives" />
          </>
        )}
        {dataGroupedByField.values
          .filter((item) => {
            return currentCategory === 'ALL'
              ? item.key !== 'EOLIEN TERRESTRE'
              : true;
          })
          .map((referentielItem) => {
            const { key, values } = referentielItem;
            const productionUnitPmax = _.isNull(values[0].bilan)
              ? values.reduce((accumulator, currentValue) => {
                  return accumulator + currentValue.pmax;
                }, 0)
              : values[0].bilan.decoupageHydro.capaciteInstallee;
            const productionUnitProduction = _.isNull(values[0].bilan)
              ? values.reduce((accumulator, currentValue) => {
                  return accumulator + currentValue.productionCapacity;
                }, 0)
              : values[0].bilan.production;
            const unavailabilityUnitProduction = values.reduce(
              (accumulator, currentValue) => {
                return accumulator + currentValue.unavailableCapacity;
              },
              0,
            );
            const data = [
              {
                name: 'Available',
                value:
                  productionUnitPmax -
                  unavailabilityUnitProduction -
                  productionUnitProduction,
                color: ['WIND', 'HYDRAULICS'].includes(
                  values[0].productionCategory,
                )
                  ? '#DADADA'
                  : '#0079D1',
              },
              {
                name: 'Unavailable',
                value: unavailabilityUnitProduction,
                color: '#D0574F',
              },
              {
                name: 'Prod',
                value: productionUnitProduction,
                color: '#41e03e',
              },
            ];

            return (
              <StyledRow
                key={key}
                className={`${getItemClass(
                  key,
                )} mark-city ${values[0].productionCategory.toLowerCase()} circle-${getSize(
                  productionUnitPmax,
                  isMobile,
                )}`}
              >
                <Col style={{ alignSelf: 'center' }}>
                  <Row className="pie-chart">
                    <Col>
                      <PieChartItem
                        data={data}
                        productionUnitName={key}
                        pmax={productionUnitPmax}
                        productionCategory={values[0].productionCategory}
                      />
                    </Col>
                  </Row>
                </Col>
              </StyledRow>
            );
          })}
      </Col>
    </Row>
  );
}

export default Map;
