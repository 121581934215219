import styled from '@emotion/styled';
import { Col, Row, Modal } from 'antd';
import React from 'react';
import { selectCurrentCategory } from 'redux/selectors/crossSelectors';
import { useSelector } from 'react-redux';
import {
  HEIGHT_TOP_SIDER_JAUGE,
  HEIGHT_TOP_SIDER_JAUGE_MOBILE,
} from '../../../../../../../utils/constants';
import useDeviceDetect from '../../../../../../../utils/useDeviceDetect';
import Infos from '../../../../../../../images/infos.svg';
import TopSiderInfosItem from './TopSiderInfosItem';
import TopSiderInfosHead from './TopSiderInfosHead';

const StyledRow = styled(Row)`
  .ant-btn {
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, 0.88);
      background-color: transparent;
    }
  }
  height: ${(props) => {
    return props.heightjauge + 5;
  }}px;
  .dispo-item {
    flex-basis: 155px;
    @media only screen and (max-width: 767px) {
      flex-basis: 90px;
    }
    .dispo-item-content {
      flex-direction: column;
      row-gap: 10px;
      @media only screen and (max-width: 767px) {
        row-gap: 0;
      }
      .item-1 {
        flex-basis: 30px;
      }
      .item-2,
      .item-3 {
        @media only screen and (max-width: 767px) {
          flex-basis: 30px;
        }
      }
    }
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 20px;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;

    img {
      padding-right: 10px;
      position: relative;
      top: -1px;
    }
  }
`;
function TopSiderInfos({ globalProduction }) {
  const currentCategory = useSelector(selectCurrentCategory);
  const isMobile = useDeviceDetect();
  const [visible, setVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [modalTitle, setModalTitle] = React.useState(null);
  const showModal = (titleT, contentT) => {
    setModalContent(contentT);
    setModalTitle(titleT);
    setVisible(true);
  };
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const HEIGHT_JAUGE = isMobile
    ? HEIGHT_TOP_SIDER_JAUGE_MOBILE
    : HEIGHT_TOP_SIDER_JAUGE;

  return (
    <StyledRow className="item-infos" heightjauge={HEIGHT_JAUGE}>
      <Col className="dispo-item" span={24}>
        <Row className="dispo-item-content">
          <Col className="item-1" span={24}>
            <TopSiderInfosHead
              currentCategory={currentCategory}
              type="disponible"
              showModal={showModal}
            />
          </Col>
          <Col span={24} className="item-2">
            <TopSiderInfosItem
              text="En production"
              value={Number(globalProduction.filiere_production.toFixed(1))}
              percent={globalProduction.filiere_productionpourcent}
              type="prod"
            />
          </Col>
          <Col className="item-3">
            <TopSiderInfosItem
              text="Utilisable"
              value={Number(globalProduction.filiere_activable.toFixed(1))}
              percent={globalProduction.filiere_activablepourcent}
              type="used"
            />
          </Col>
        </Row>
      </Col>
      <Col className="dispo-item">
        <Row className="dispo-item-content">
          <Col className="text-1 item-1" span={24}>
            <TopSiderInfosHead
              currentCategory={currentCategory}
              type="inaccessible"
              showModal={showModal}
            />
          </Col>
          <Col span={24} className="item-2">
            <TopSiderInfosItem
              text="Non utilisable"
              value={Number(globalProduction.filiere_inaccessible.toFixed(1))}
              percent={globalProduction.filiere_inaccessiblepourcent}
              type="inacc"
            />
          </Col>
          <Col className="item-3">
            <TopSiderInfosItem
              text="Indisponible"
              value={Number(globalProduction.filiere_maintenance.toFixed(1))}
              percent={globalProduction.filiere_maintenancepourcent}
              type="indispo"
            />
          </Col>
        </Row>
      </Col>
      <StyledModal
        type="info"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={850}
      >
        <h3>
          <img src={Infos} alt="infos" />
          {modalTitle}
        </h3>
        <p dangerouslySetInnerHTML={modalContent} />
      </StyledModal>
    </StyledRow>
  );
}

export default TopSiderInfos;
