import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
// import useDeviceDetect from 'utils/useDeviceDetect';
import { DisplayModes } from 'enums/DisplayModes';
import {
  selectCurrentCategory,
  selectDisplayMode,
} from 'redux/selectors/crossSelectors';
import Map from './map';
import SlicesBody from './slices';
// import InfoSider from '../siders/InfoSider';

const StyledRow = styled(Row)`
  .slices-box {
    min-width: 920px;
  }
  @media only screen and (max-width: 767px) {
    &.over {
      overflow-x: scroll;
    }
  }
`;
function Main() {
  const displayMode = useSelector(selectDisplayMode);
  const currentCategory = useSelector(selectCurrentCategory);

  // const isMobile = useDeviceDetect();
  return (
    <StyledRow
      className={`main ${
        displayMode === DisplayModes.SLICES && currentCategory !== 'ALL'
          ? 'over'
          : ''
      }`}
    >
      <Col className="map-box" span={24}>
        {displayMode === DisplayModes.MAP && <Map />}
      </Col>
      <Col className="slices-box" span={24}>
        {displayMode === DisplayModes.SLICES &&
          (currentCategory === 'ALL' ? <Map /> : <SlicesBody />)}
      </Col>
      {/* {isMobile && ['HYDRAULICS', 'ALL'].includes(currentCategory) && (
        <Col span={24}>
          <InfoSider />
        </Col>
      )} */}
    </StyledRow>
  );
}

export default Main;
