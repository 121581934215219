import { Col, Row } from 'antd';
import React from 'react';
import useDeviceDetect from 'utils/useDeviceDetect';
import styled from '@emotion/styled';
import TopSiderBody from './TopSiderBody';
import TopSiderButton from './TopSiderButton';

const StyledRow = styled(Row)`
  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
`;

function TopSider() {
  const isMobile = useDeviceDetect();
  const gutter = isMobile ? [0, 12] : [0, 27];
  return (
    <StyledRow gutter={gutter}>
      <Col span={24}>
        <TopSiderBody />
      </Col>
      {!isMobile && (
        <Col span={24} style={{ textAlign: 'center' }}>
          <TopSiderButton />
        </Col>
      )}
    </StyledRow>
  );
}

export default TopSider;
