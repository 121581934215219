/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Card } from 'antd';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import useDeviceDetect from 'utils/useDeviceDetect';
import Close from 'images/close-white.svg';
import Filters from './filters';
import ModeButtons from './filters/chips/ModeButtons';
import Siders from './siders';
import Main from './main';
import { DisplayModes } from '../../../../../enums/DisplayModes';
import {
  selectCurrentCategory,
  selectDisplayMode,
} from '../../../../../redux/selectors/crossSelectors';
import InfoSider from './siders/InfoSider';
import RefreshDate from './filters/refresh';

const StyledRow = styled(Row)`
  padding: 15px 5px;
  column-gap: 27px;
  row-gap: 32px;
  .content-box {
    flex-wrap: nowrap;
  }
  .sider-infos {
    background-color: #009dd1;
    border-radius: 8px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 15px;
    padding-right: 30px;
  }
  .close-img {
    position: absolute;
    right: 16px;
    cursor: pointer;
    width: 26px;
    height: 26px;
  }
  .content-filters {
    height: 48px;
  }
  .card-slices {
    box-shadow: none;
    .ant-card-body {
      background: #19252a;
      box-shadow: none;
      padding-top: 0;
      border-radius: 0;
      padding-left: 14px;
      padding-right: 0px;
    }
  }
  .card-map {
    box-shadow: none;
    .ant-card-body {
      background: #19252a;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
    }
  }
  .siders-col {
    flex-basis: 301px;
  }
  .main-col {
    flex-basis: auto;
  }

  @media only screen and (max-width: 767px) {
    padding: 25px 0;
    row-gap: 25px;
    .sider-infos {
      margin-left: 15px;
      margin-right: 30px;
    }
    .card-map {
      box-shadow: none;
    }
    .content-box {
      flex-direction: column;
    }
    .siders-col {
      flex-basis: 236px;
    }
    .content-filters {
      height: 62px;
      width: 100%;
      overflow-x: scroll;
      padding-top: 8px;
    }
    .card-slices {
      box-shadow: none;
    }
  }
`;
function Content() {
  const displayMode = useSelector(selectDisplayMode);
  const isMobile = useDeviceDetect();
  const currentCategory = useSelector(selectCurrentCategory);
  const [close, setClose] = useState(true);
  const handleClose = () => {
    setClose(false);
  };
  return (
    <StyledRow>
      <Col span={24} className="content-filters">
        <Row>
          <Col span={24}>
            <Filters />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row style={{ columnGap: '0' }} className="content-box">
          <Col className="siders-col">
            <Siders />
          </Col>
          {isMobile && (
            <Col>
              <ModeButtons />
            </Col>
          )}
          <Col className="main-col">
            <Card
              bordered={false}
              className={`${
                displayMode === DisplayModes.SLICES && currentCategory !== 'ALL'
                  ? 'card-slices'
                  : 'card-map'
              }`}
            >
              <Main />
            </Card>
          </Col>
          {isMobile && (
            <Col>
              <RefreshDate />
            </Col>
          )}
        </Row>
      </Col>
    </StyledRow>
  );
}

export default Content;
