import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentCategory } from './crossSelectors';

export const selectPmaxItems = (state) => {
  return [...state.pmax.items];
};
export const selectPmaxCapacityCurrentCategory = createSelector(
  [selectPmaxItems, selectCurrentCategory],
  (pmaxItems, category) => {
    const result = pmaxItems.find((item) => {
      return item.productionCategory === category;
    });
    return result.pmax;
  },
);

// Capa max All = 25532(HYDRAULICS) + 1342(BIOMASS)+ 14482(SOLAR) + 12946(FOSSIL_GAS) + 1816(FOSSIL_HARD_COAL) + 2559(FOSSIL_OIL) + 21450(WIND) + 61370(NUCLEAR)  = 141497 ---> /pmax
// Capa max ALL est calculable en faisant la somme sans TIDAL, HYDRO_PUMPED_STORAGE, HYDRO_WATER_RESERVOIR et ALL

// Indispo All = 6012(HYDRAULICS) + 2225(FOSSIL_GAS) + 1160(FOSSIL_HARD_COAL) + 170(FOSSIL_OIL) + 0(WIND) + 30377(NUCLEAR) = 39944 ---> /data(itemsPerProductionUnit)

// Disponible = capa step + capalac -abs(prodstep) - prodlac - indispoStep - indispoLac

// Producion all = 4476(HYDRAULICS) + 326(BIOMASS) + 3812(FOSSIL_GAS) + 15(FOSSIL_HARD_COAL) + 138(FOSSIL_OIL) + 3991 + 0(WIND_ONSHORE + WIND_OFFSHORE) + 29971(NUCLEAR) + SOLAR (8036) = 50765
// la production solaire n'existe pas dans itemsPerProductionType
// la production de l'hydrau est = step + lac + file de leau = 1146 + 3330 + 0 (la valeur reçue de l'api est -530, on la force à 0)

// pmaxstep + lac = 5065 + 8787 = 13838
// dispo = capa (step + lac) - abs(prodStep) - prodLac - indispo (step + lac) = 13838 -
