import { Col, Row } from 'antd';
import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { selectCurrentCategory } from 'redux/selectors/crossSelectors';
import SubSliceTitle from './SubSliceTitle';

const StyledRow = styled(Row)`
  width: 86px;
  .slice-content-col {
    color: white;
  }
`;

function SubSlice({ unitName, pmax, production, available }) {
  const currentCategory = useSelector(selectCurrentCategory);
  const getClassName = () => {
    // const limit = (1 / 10) * (production + available);
    if (available === 0) {
      return 'fully-slice-red';
    }
    if (production <= 0) {
      return ['WIND'].includes(currentCategory)
        ? 'prod-sub-slice-gris'
        : 'prod-sub-slice-blue';
    }
    if (['NUCLEAR'].includes(currentCategory)) {
      return production >= 0.97 * available
        ? 'prod-sub-slice-green'
        : 'prod-sub-slice-green-blue';
    }
    if (['WIND'].includes(currentCategory)) {
      return available - production <= 20
        ? 'prod-sub-slice-green'
        : 'prod-sub-slice-green-gris';
    }

    return available - production <= 20
      ? 'prod-sub-slice-green'
      : 'prod-sub-slice-green-blue';
  };
  return (
    <StyledRow className="slice-content">
      <Col span={24}>
        <SubSliceTitle pmax={pmax} isIconVisible={pmax !== available} />
      </Col>
      <Col span={24} className={`slice-content-col ${getClassName()}`}>
        <Row>
          <Col span={24} className="slice-content-capacity">
            {Math.round(production)} <span>MW</span>
          </Col>
          <Col className="slice-content-city" span={24}>
            {unitName}
          </Col>
        </Row>
      </Col>
    </StyledRow>
  );
}

export default SubSlice;
