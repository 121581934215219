/* eslint-disable camelcase */
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React from 'react';
import useDeviceDetect from 'utils/useDeviceDetect';
import {
  HEIGHT_TOP_SIDER_JAUGE,
  HEIGHT_TOP_SIDER_JAUGE_MOBILE,
} from 'utils/constants';

const StyledRow = styled(Row)`
  height: ${(props) => {
    return props.heightjauge + 5;
  }}px;
  border-radius: 10px;
  overflow: hidden;
  &.percents {
    flex-direction: column;
    .productions-percent {
      flex-basis: ${(props) => {
        return props.production;
      }}px;
      width: 100%;
      background: linear-gradient(180deg, #34c601 0%, #46eb57 100%);
    }
    .up-percent {
      flex-basis: ${(props) => {
        return props.rest;
      }}px;
      background: rgba(0, 120, 207, 1);
      width: 100%;
    }
    .inaccessible-percent {
      flex-basis: ${(props) => {
        return props.inaccessible;
      }}px;
      width: 100%;
      background: #dadada;
    }
    .down-percent {
      flex-basis: ${(props) => {
        return props.unavailable;
      }}px;
      width: 100%;
      background: #d0574f;
    }
    .separator-percent {
      flex-basis: 5px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    height: ${(props) => {
      return props.heightjauge + 2.5;
    }}px;
    border-radius: 3px;
    &.percents {
      .separator-percent {
        flex-basis: 2.5px;
      }
    }
  }
`;
function TopSiderTubes({ globalProduction }) {
  const {
    filiere_maintenancepourcent,
    filiere_productionpourcent,
    filiere_activablepourcent,
    filiere_inaccessiblepourcent,
  } = globalProduction;
  const isMobile = useDeviceDetect();
  const HEIGHT_JAUGE = isMobile
    ? HEIGHT_TOP_SIDER_JAUGE_MOBILE
    : HEIGHT_TOP_SIDER_JAUGE;
  return (
    <StyledRow
      unavailable={(HEIGHT_JAUGE * filiere_maintenancepourcent) / 100}
      production={(HEIGHT_JAUGE * filiere_productionpourcent) / 100}
      rest={(HEIGHT_JAUGE * filiere_activablepourcent) / 100}
      align="middle"
      gutter={13}
      wrap={false}
      style={{ margin: 0 }}
      inaccessible={(HEIGHT_JAUGE * filiere_inaccessiblepourcent) / 100}
      className="percents"
      heightjauge={HEIGHT_JAUGE}
    >
      <Col className="productions-percent" span={24} />
      {filiere_activablepourcent !== 0 && (
        <Col className="up-percent" span={24} />
      )}
      <Col className="separator-percent" span={24} />
      {filiere_inaccessiblepourcent !== 0 && (
        <Col className="inaccessible-percent" span={24} />
      )}
      <Col className="down-percent" span={24} />
    </StyledRow>
  );
}

export default TopSiderTubes;
