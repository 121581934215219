import styled from '@emotion/styled';
import { Button } from 'antd';
import React from 'react';

const StyledButton = styled(Button)`
  &.summary {
    background-color: white;
    padding: 4px 5px;
    height: auto;
    color: #009dd1;
    border: 2px solid #009dd1;
    border-radius: 8px;

    @media only screen and (max-width: 767px) {
      position: relative;
      top: 2px;
    }
  }
  &.chips {
    svg {
      margin-right: 3px;
    }
    line-height: 16px;
    padding: 3px 5px;
    background-color: transparent;
    border: 2px solid #ffffff;
    &.chips-nuclear {
      border: 2px solid #ffe18e;
    }
    &.chips-hydraulics {
      border: 2px solid #75c7f5;
    }
    &.chips-fossil_gas {
      border: 2px solid #f18888;
    }
    &.chips-wind {
      border: 2px solid #39b593;
    }
    &.chips-fossil_oil {
      border: 2px solid #d5aaff;
    }
    &.chips-fossil_hard_coal {
      border: 2px solid #8c7a49;
    }

    height: 32px;
    &.active {
      background: #e9f7fc;
      border: 1px solid #009dd1;
      color: #004d66;
    }

    &.tranches {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      position: relative;
      left: 2px;
    }
    &.carte {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &.all-chips {
      background: #e9f7fc;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      border: 1px solid #009dd1;
      color: #004d66;
    }
  }
  &.refresh {
    width: 54px;
    height: 48px;
    padding: 12px 15px;
    border: 2px solid #ffffff;
    border-radius: 8px;
    background-color: transparent;
  }
  @media only screen and (max-width: 767px) {
    &.chips {
      padding: 0 5px;
      height: 40px;
      border-radius: 4px;
    }
  }
`;
function Buttons({
  children,
  styling,
  active,
  clickHandler,
  icon,
  loading,
  disabled,
}) {
  return (
    <StyledButton
      className={`${styling} ${active ? 'active' : ''} `}
      type="primary"
      onClick={clickHandler}
      icon={icon}
      loading={loading}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
}

export default Buttons;
