/* eslint-disable camelcase */
import { Col, Row } from 'antd';
import React from 'react';
import styled from '@emotion/styled';
import {
  WIDTH_JAUGE_SECTOR_MODAL,
  WIDTH_JAUGE_SECTOR_MODAL_MOBILE,
} from '../../../utils/constants';
import useDeviceDetect from '../../../utils/useDeviceDetect';

const StyledRow = styled(Row)`
  &.jauge-modal {
    border-radius: 10px;
    overflow: hidden;
    width: ${(props) => {
      return props.size;
    }}px;
    height: 25px;
    .production {
      background: linear-gradient(91.66deg, #36c90a 10.18%, #46ea54 91.31%);
      width: ${(props) => {
        return props.categorylastproduction;
      }}px;
    }
    .inaccessible {
      width: ${(props) => {
        return props.inaccessiblevalue;
      }}px;
      background: #dadada;
    }
    .rest {
      background: linear-gradient(91.66deg, #0079d1 10.18%, #009dd1 91.31%);
      width: ${(props) => {
        return props.rest;
      }}px;
    }
    .unavailable {
      background: #d0574f;
      width: ${({ unavailablecapacity }) => {
        return unavailablecapacity;
      }}px;
    }
  }
`;
export function Jauges({ globalDetails }) {
  const isMobile = useDeviceDetect();
  const HEIGHT_JAUGE = isMobile
    ? WIDTH_JAUGE_SECTOR_MODAL_MOBILE
    : WIDTH_JAUGE_SECTOR_MODAL;

  const {
    filiere_productionpourcent,
    filiere_maintenancepourcent,
    filiere_activablepourcent,
    filiere_inaccessiblepourcent,
  } = globalDetails;

  return (
    <StyledRow
      className="jauge-modal"
      unavailablecapacity={(HEIGHT_JAUGE * filiere_maintenancepourcent) / 100}
      categorylastproduction={(HEIGHT_JAUGE * filiere_productionpourcent) / 100}
      rest={(HEIGHT_JAUGE * filiere_activablepourcent) / 100}
      size={HEIGHT_JAUGE}
      inaccessiblevalue={(HEIGHT_JAUGE * filiere_inaccessiblepourcent) / 100}
    >
      <Col className="production" />
      <Col className="rest" />
      <Col className="inaccessible" />
      <Col className="unavailable" />
    </StyledRow>
  );
}
