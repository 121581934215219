import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentCategory } from 'redux/selectors/crossSelectors';
import {
  HEIGHT_TOP_SIDER_JAUGE,
  HEIGHT_TOP_SIDER_JAUGE_MOBILE,
} from 'utils/constants';
import { selectGlobalProductionDetailsPerType } from 'redux/selectors/dataSelectors';
import TopSiderInfos from './TopSiderInfos';
import TopSiderTubes from './TopSiderTubes';

const StyledRow = styled(Row)`
  height: ${HEIGHT_TOP_SIDER_JAUGE + 5}px;
  column-gap: 10px;
  .col-jauges {
    flex: 0 0 50px;
  }
  .col-infos {
    margin-top: 0;
  }
  @media only screen and (max-width: 767px) {
    height: ${HEIGHT_TOP_SIDER_JAUGE_MOBILE + 6}px;
    .col-jauges {
      flex: 0 0 21px;
    }
  }
`;
function TopSiderBody() {
  const currentCategory = useSelector(selectCurrentCategory);
  const globalDetails = useSelector(selectGlobalProductionDetailsPerType);

  return (
    <StyledRow className="top-sider-body" wrap={false}>
      <Col className="col-jauges">
        <TopSiderTubes globalProduction={globalDetails[currentCategory]} />
      </Col>
      <Col flex="auto" className="col-infos">
        <TopSiderInfos globalProduction={globalDetails[currentCategory]} />
      </Col>
    </StyledRow>
  );
}

export default TopSiderBody;
